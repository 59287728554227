import React from "react";

import logo from "../assets/logo.png";

export default function Header() {
    return(
        <div className="w-full sm:h-20 h-12 bg-custom-900 border-b-2 border-custom-300 flex justify-around items-center px-4">
            <h1 style={{fontFamily: 'Roboto'}} className="text-custom-100 sm:text-xl sm:w-1/3 text-lg tracking-wider">Links to Kishan's websites</h1>
            <img src={logo} className="sm:h-32 h-24 sm:mt-20 mt-12" alt="" />
            <div className="sm:w-1/3"></div>
        </div>
    );
}