import React from "react";

import data from "./data";

import Header from "./components/Header";
import Categories from "./components/Categories";

export default function App() {
    return(
        <div className="w-screen">
            <Header />
            <Categories data={data} />
        </div>
    );
}
