import React from "react";

import Item from "./Item";

export default function Category(props) {
    const items = props.items.map((item, index) => (
        <Item key={item.id} name={item.name} description={item.description} link={item.link} last={index >= (props.items.length - 1)} />
    ));

    return(
        <div className="sm:w-1/2 w-5/6 mb-8">
            <h1 style={{fontFamily: 'Roboto'}} className="sm:text-2xl text-xl tracking-wide text-custom-500 underline">{props.name}</h1>
            <div className="sm:mt-4 mt-2 bg-custom-900 rounded-lg border-2 border-custom-300">
                {items}
            </div>
        </div>
    );
}