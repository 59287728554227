import React from "react";

import Category from "./Category";

export default function Categories(props) {
    const categoryList = props.data.map((el, index) => (
        <Category key={index} name={el.name} items={el.items} />
    ));

    return(
        <div className="w-screen flex flex-col items-center justify-center text-center sm:mt-20 mt-10">
            {categoryList}
        </div>
    );
}