const categories = [
    {
        name: 'General Purpose Websites',
        items: [
            {
                id: 'a1',
                name: "KinesisGames' Official Website",
                description: 'To showcase the games I make.',
                link: 'https://www.kinesis.games/'
            },
            {
                id: 'a8',
                name: "Portfolio",
                description: 'My personal portfolio.',
                link: 'https://portfolio.kinesis.games/'
            },
            {
                id: 'a2',
                name: "Blog",
                description: 'My personal blog.',
                link: 'https://blog.kinesis.games/'
            },
            {
                id: 'a3',
                name: "Downloads",
                description: 'Website to provide builds for the games I make.',
                link: 'https://downloads.kinesis.games/'
            },
            {
                id: 'a4',
                name: "Curriculum Vitae",
                description: 'My CV/Resume.',
                link: 'https://cv.kinesis.games/'
            },
            {
                id: 'a5',
                name: "Changelog",
                description: 'To display long changelogs for updates to the games I make.',
                link: 'https://changelog.kinesis.games/'
            },
            {
                id: 'a6',
                name: "EdgeKing810",
                description: 'A personal website with short stories.',
                link: 'https://edgeking810.kinesis.games/'
            },
            {
                id: 'a7',
                name: "Kinesis OS",
                description: 'Installation instructions and details about the Linux distro I made.',
                link: 'https://os.kinesis.games/'
            },
        ],
    },
    {
        name: 'Feature Rich & Interactable Websites',
        items: [
            {
                id: 'b6',
                name: "Kinesis Pass",
                description: 'A password manager offering more than only basic features.',
                link: 'https://pass.kinesis.games/'
            },
            {
                id: 'b7',
                name: "Kinesis Chat",
                description: 'A live messaging app.',
                link: 'https://chat.kinesis.games/'
            },
            {
                id: 'b1',
                name: "Emby",
                description: 'My media streaming platform.',
                link: 'https://emby.kinesis.games/'
            },
            {
                id: 'b2',
                name: "Reactor",
                description: 'A hub to showcase the web apps I make.',
                link: 'https://reactor.kinesis.games/'
            },
            {
                id: 'b3',
                name: "Webserver",
                description: 'A LAMP Stack hosted on my server in docker containers.',
                link: 'https://webserver.kinesis.games/'
            },
            {
                id: 'b4',
                name: "phpMyAdmin",
                description: 'A phpMyAdmin interface to test and manage MySQL databases.',
                link: 'https://admin.kinesis.games/'
            },
            {
                id: 'b5',
                name: "Stats",
                description: 'Stats on my server (password protected).',
                link: 'https://stats.kinesis.games/'
            },
        ]
    },
    {
        name: 'Social',
        items: [
            {
                id: 'c1',
                name: "Facebook",
                description: '',
                link: 'https://www.facebook.com/com.KinesisGames.KishanTakoordyal'
            },
            {
                id: 'c2',
                name: "Instagram",
                description: '',
                link: 'https://www.instagram.com/kishan_takoordyal/'
            },
            {
                id: 'c3',
                name: "LinkedIn",
                description: '',
                link: 'https://www.linkedin.com/in/kishan-takoordyal-99816b180/'
            },
            {
                id: 'c4',
                name: "Twitter",
                description: '',
                link: 'https://www.twitter.com/EdgeKing810/'
            },
            {
                id: 'c5',
                name: "Github",
                description: '',
                link: 'https://github.com/EdgeKing810/'
            },
            {
                id: 'c6',
                name: "Email",
                description: '',
                link: 'mailto:kishan@kinesis.games'
            },
        ]
    },
];

export default categories;
