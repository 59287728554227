import React from "react";

export default function Item(props) {
    return(
        <a href={props.link} target="_blank" rel="noopener noreferrer">
            <div className={`${props.last ? '' : 'border-b-2 border-custom-300'} hover:bg-custom-700`}>
                <h1 className={`${props.description.length ? 'py-2' : 'py-4'} sm:text-lg text-md text-custom-100 py-2`} style={{fontFamily: 'Noto Sans HK'}}>{props.name}</h1>
                <p className={props.description.length ? 'text-custom-300 sm:text-sm text-sm pb-4 tracking-wide' : ''} style={{fontFamily: 'Ubuntu Condensed'}}>{props.description}</p>
            </div>
        </a>
    );
}